import React, { useEffect, useState } from 'react';
import { Checkbox, Typography } from 'antd';
import componyIcon from 'assets/images/companyIcon.svg';
import chevronRightIcon from 'assets/images/chevron_right.svg';

export const CardBox = ({
  id,
  title,
  checked,
  icon,
  setChecked,
  disabled = false,
  bodyStyle = {},
  iconProps,
  submitOnClick,
}) => {
  const [imgSrc, setImgSrc] = useState(icon);
  const handleError = (e) => {
    e.stopPropagation();
    setImgSrc(componyIcon);
  };
  return (
    <div
      className={`active-card-button ${
        checked === id ? 'active-card-button--checked' : ''
      } ${disabled ? 'active-card-button--disabled' : ''}`}
      role="button"
      aria-disabled={disabled}
      onClick={() => !disabled && setChecked && setChecked(id)}
      style={bodyStyle}>
      <span>
        {icon && (
          <img
            {...iconProps}
            alt={title}
            style={{ marginRight: '1rem' }}
            src={imgSrc}
            onError={handleError}
          />
        )}
        <Typography.Text style={{ color: disabled ? '#000000A8' : 'inherit' }}>
          {title}
        </Typography.Text>
      </span>
      {submitOnClick ? (
        <img alt="Chevron Right" src={chevronRightIcon} />
      ) : (
        <Checkbox
          style={{ justifySelf: 'flex-end' }}
          className="card-box__checkbox"
          checked={checked === id}
          aria-disabled={disabled}
          disabled={disabled}
        />
      )}
    </div>
  );
};

const CardBoxGroup = ({
  options,
  cardProps,
  submitOnClick,
  checked,
  setChecked,
}) => {
  // TODO: create a function that takes an id then updates state and calls the onSelect.
  // Instead of using state, use context to separate the child component and use card box group as a wrapper e.g. Form.Item.
  return (
    <div
      style={{
        display: 'flex',
        gap: '1rem',
        flexDirection: 'column',
      }}>
      {options.map(({ id, title, icon, ...others }) => {
        return (
          <CardBox
            key={title + id}
            id={id}
            icon={icon}
            title={title}
            setChecked={setChecked}
            checked={checked}
            submitOnClick={submitOnClick}
            {...others}
            {...cardProps}
          />
        );
      })}
    </div>
  );
};

export default CardBoxGroup;
