import { ERROR_CODE_INVALID } from 'constants';
import { useState, useRef } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { checkCode, getUsersCredentials, resendCode } from './api';
import SignInOptionsForm from './form';
import useDeleteLogin from './hooks/useDeleteLogin';
import useGetCodeMutation from './hooks/useGetCodeMutation';
import useResendCode from './hooks/useResendCode';
import useSelectWorkSpace from './hooks/useSelectWorkSpace';
import useSigninOptionStartQuery from './hooks/useSiginOptionStartQuery';
import useUserAgent from 'hooks/useUserAgent';
import usePutAssertion from 'features/signIn/hooks/usePutAssertion';
import { useGetSessionQuery } from 'features/signIn/hooks';
import { requestLoginPasswordless } from 'features/signIn/utils/FidoAuthentication';
import { useNotifContext } from 'store';
import Cookies from 'js-cookie';

const SignInOptions = ({ query }) => {
  const { isMac, isMobile, browser } = useUserAgent();
  const navigate = useNavigate();
  const notif = useNotifContext();
  const [resendForm, setResendForm] = useState();
  const [recovery, setRecovery] = useState('2');
  const [workSpaceId, setWorkSpaceId] = useState(undefined);
  const [passwordLessLoading, setPasswordLessLoading] = useState(false);
  const passwordLess = usePutAssertion(query);
  const {
    data: challengeData,
    refetch,
    error: challengeError,
    loading: challengeLoading,
  } = useGetSessionQuery(query);
  const { isLoading, data } = useSigninOptionStartQuery(query);
  const { workSpaceData, workSpaceLoading, workSpaceMutate, redirectLoading } =
    useSelectWorkSpace(query);
  const { deleteMutate } = useDeleteLogin();
  const modalRef = useRef();
  const counterRef = useRef();
  const refCaptcha = useRef();
  const resend = useResendCode(query, counterRef);
  const {
    modalToggle,
    needCaptcha,
    checkCodeMutate,
    getCodeMutate,
    getCodeData,
    workSpaces,
    checkCodeError,
    getCodeLoading,
    checkCodeLoading,
    codeRedirectLoading,
  } = useGetCodeMutation(query);
  if (checkCodeError) {
    modalRef.current.setSpin(false);
    modalRef.current.form.setFields([
      {
        name: 'verifyCode',
        errors: [ERROR_CODE_INVALID],
      },
    ]);
    if (refCaptcha.current) {
      refCaptcha.current.resetCaptcha();
      modalRef.current.form.setFieldsValue({ captcha: null });
    }
  }

  const passwordLessHandler = async () => {
    const usersCredentials = await getUsersCredentials({ email: data.email });
    const convertedUsersCredentials = usersCredentials.data.map(
      (userCredential) => {
        return {
          type: 'public-key',
          id: Uint8Array.from(atob(userCredential), (c) => c.charCodeAt(0)),
        };
      }
    );

    requestLoginPasswordless(
      challengeData?.fidoRequest?.result,
      passwordLess.mutateAsync,
      (response) => {
        if (response?.redirectURL) {
          window.location.replace(response.redirectURL);
        } else {
          if (response?.response?.data?.message)
            notif.errorNotif(response?.response?.data?.message);
          else notif.errorNotif(response?.message);
          setPasswordLessLoading(false);
        }
      },
      convertedUsersCredentials
    );
  };

  const submitHandler = (values, callback) => {
    if (workSpaces.length > 0) {
      workSpaceMutate({ workspace: workSpaceId });
    } else if (values.type === '4') {
      if (isMobile && !isMac) {
        window.location.href =
          'idmelon://signin/' +
          (query?.req ? `?req=${query?.req}` : browser ? '?' : '') +
          (query?.req && browser
            ? `&browser=${browser}`
            : browser
            ? `browser=${browser}`
            : '');
      } else {
        let session = null;
        if (window.sessionStorage) {
          session = sessionStorage.getItem('session');
        } else {
          session = Cookies.get('session');
        }
        if (session) {
          setPasswordLessLoading(true);
          refetch()
            .then(() => {
              passwordLessHandler();
            })
            .catch(() => {
              setPasswordLessLoading(false);
            });
        } else {
          alert("This browser doesn't support session storage.");
        }
      }
    } else {
      setResendForm({ ...values });
      getCodeMutate(values);
    }
    callback && callback();
  };
  const changePageHandler = () => {
    navigate({
      pathname: '/',
      search: `?${createSearchParams(query)}`,
    });
  };
  const closeModalHandler = () => {
    deleteMutate();
    changePageHandler();
  };
  const submitCodeHandler = (values) => {
    checkCodeMutate(values);
  };
  const resendFormHandler = () => {
    if (refCaptcha.current) {
      refCaptcha.current.resetCaptcha();
      modalRef.current.form.setFieldsValue({ captcha: null });
    }
    resend.mutate(resendForm);
  };
  return (
    <SignInOptionsForm
      onSubmit={submitHandler}
      query={query}
      data={data}
      onSetRecovery={setRecovery}
      recovery={recovery}
      modalRef={modalRef}
      counterRef={counterRef}
      refCaptcha={refCaptcha}
      modalToggle={modalToggle}
      onCloseModal={closeModalHandler}
      onSubmitCode={submitCodeHandler}
      onResendForm={resendFormHandler}
      needCaptcha={needCaptcha}
      codeData={getCodeData}
      workSpaces={workSpaces}
      workSpaceId={workSpaceId}
      setWorkSpaceId={setWorkSpaceId}
      showSelectWorkspace={workSpaces.length > 0}
      signInOptionsDataLoading={isLoading}
      loading={workSpaceLoading || passwordLessLoading}
      getCodeLoading={getCodeLoading}
      onChangePage={changePageHandler}
      modalLoading={resend.isLoading || checkCodeLoading}
      redirectLoading={redirectLoading || codeRedirectLoading}
    />
  );
};

export default SignInOptions;
