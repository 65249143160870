import { useEffect } from 'react';
import { Form, Skeleton } from 'antd';
import { CHOOSE_AUTHENTICATION_METHOD } from 'constants';
import CardBoxGroup from 'component/ui/cardBoxGroup';
import passkeyIcon from 'assets/images/passkey-b.svg';
import mailIcon from 'assets/images/mail.svg';
import smsIcon from 'assets/images/sms.svg';
import callCallingIcon from 'assets/images/call-calling.svg';

const SigninOptionFormElement = ({
  state,
  loading,
  checked,
  setChecked,
  onSetRecovery,
  onSubmit,
}) => {
  const { email, hasPhoneNumber, phoneX } = state || {};

  useEffect(() => {
    if (checked === null) {
      return;
    }
    onSetRecovery(checked);
    onSubmit({ type: checked }, () => setChecked(null));
  }, [checked]);

  const sendCodeOptions = [
    {
      title: 'Passkey',
      id: '4',
      icon: passkeyIcon,
    },
    {
      title: `Email: ${email ?? ''}`,
      id: '2',
      icon: mailIcon,
    },
    {
      title: `Message: ${hasPhoneNumber ? phoneX : ''}`,
      id: '1',
      disabled: !hasPhoneNumber,
      icon: smsIcon,
    },
    {
      title: `Call: ${hasPhoneNumber ? phoneX : ''}`,
      id: '3',
      disabled: !hasPhoneNumber,
      icon: callCallingIcon,
    },
  ];
  return (
    <div>
      <Form.Item
        style={{ marginBottom: '0px' }}
        name="type"
        label={
          <span
            style={{
              fontWeight: 'bolder',
              fontSize: '16px',
              marginBottom: '16px',
            }}>
            {CHOOSE_AUTHENTICATION_METHOD}
          </span>
        }>
        <Skeleton loading={loading} active>
          <CardBoxGroup
            options={sendCodeOptions}
            submitOnClick={true}
            checked={checked}
            setChecked={setChecked}
          />
        </Skeleton>
      </Form.Item>
    </div>
  );
};

export default SigninOptionFormElement;
