import { useEffect, useRef, useState } from 'react';
import { LOGIN_ID_MELON_ACCOUNT, SIGN_IN, SP_NAME } from 'constants';
import SignInForm from './form';
import { useGetSessionQuery } from './hooks';
import { useSendEmailMutate } from './hooks/useSendEmail';
import { requestLoginPasswordless } from './utils/FidoAuthentication';
import usePutAssertion from './hooks/usePutAssertion';
import Cookies from 'js-cookie';
import useUserAgent from 'hooks/useUserAgent';
import { useNotifContext } from 'store';
import { Spin } from 'antd';

const SignIn = ({ query }) => {
  const refCaptcha = useRef();

  let title = '';
  if (query?.sp && SP_NAME(query.sp.toLowerCase()) !== 'IDmelon Panel') {
    title = LOGIN_ID_MELON_ACCOUNT(query.sp.toLowerCase());
  } else {
    title = SIGN_IN;
  }
  const {
    data: challengeData,
    refetch,
    error: challengeError,
    loading: challengeLoading,
  } = useGetSessionQuery(query);
  const {
    data,
    error,
    loading: submitLoading,
    mutate,
  } = useSendEmailMutate(query, refCaptcha);
  const [passwordLessLoading, setPasswordLessLoading] = useState(false);
  const [formState, setFormState] = useState({ step: 0, current: 0 });
  const passwordLess = usePutAssertion(query);
  const { isMac, isMobile, browser } = useUserAgent();
  const notif = useNotifContext();
  const next = () => {
    setFormState((prevState) => {
      return {
        step: 0,
        current: 1,
      };
    });
  };
  const prev = () => {
    setFormState((prevState) => {
      return {
        step: 0,
        current: 0,
      };
    });
  };
  const passwordLessHandler = () => {
    requestLoginPasswordless(
      challengeData?.fidoRequest?.result,
      passwordLess.mutateAsync,
      (response) => {
        if (response?.redirectURL) {
          window.location.replace(response.redirectURL);
        } else {
          if (response?.response?.data?.message)
            notif.errorNotif(response?.response?.data?.message);
          else notif.errorNotif(response?.message);
          setPasswordLessLoading(false);
        }
      }
    );
  };
  useEffect(() => {
    if (challengeData) {
      if (query?.sp && query?.email && query?.authType) {
        mutate({ email: query.email });
      } else if (
        !query?.sp?.toLowerCase().includes('idmelon') &&
        query?.idp_init?.toLowerCase().includes('false') &&
        !isMobile
      ) {
        setFormState((prevState) => {
          return {
            ...prevState,
            step: 1,
          };
        });
        setPasswordLessLoading(true);
        passwordLessHandler();
      }
    }
  }, [challengeData]);
  const onFinish = (values) => {
    if (formState.current === 1) mutate(values);
    else {
      if (isMobile && !isMac) {
        window.location.href =
          'idmelon://signin/' +
          (query?.req ? `?req=${query?.req}` : browser ? '?' : '') +
          (query?.req && browser
            ? `&browser=${browser}`
            : browser
            ? `browser=${browser}`
            : '');
      } else {
        setFormState((prevState) => {
          return {
            ...prevState,
            step: 1,
          };
        });
        let session = null;
        if (window.sessionStorage) {
          session = sessionStorage.getItem('session');
        } else {
          session = Cookies.get('session');
        }
        if (session) {
          setPasswordLessLoading(true);
          refetch()
            .then(() => {
              passwordLessHandler();
            })
            .catch(() => {
              setPasswordLessLoading(false);
            });
        } else {
          alert("This browser doesn't support session storage.");
        }
      }
    }
  };
  const signInWithIdMelonHandler = () => {
    window.location.href =
      'idmelon://signin/' +
      (query?.req ? `?req=${query?.req}` : browser ? '?' : '') +
      (query?.req && browser
        ? `&browser=${browser}`
        : browser
        ? `browser=${browser}`
        : '');
  };
  return query?.sp && query?.email && query?.authType ? (
    <Spin spinning />
  ) : (
    <SignInForm
      next={next}
      prev={prev}
      title={title}
      onFinish={onFinish}
      step={formState.step}
      current={formState.current}
      refCaptcha={refCaptcha}
      loading={challengeLoading}
      submitLoading={submitLoading}
      query={query}
      onSignInSecondButton={signInWithIdMelonHandler}
      isMac={isMac}
      isMobile={isMobile}
      passwordLessLoading={passwordLessLoading}
      emailSubmitLoading={submitLoading}
    />
  );
};

export default SignIn;
