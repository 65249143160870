import { useEffect } from 'react';
import { Typography } from 'antd';
import CardBoxGroup from 'component/ui/cardBoxGroup';

const WorkSpaceForm = ({ workSpaces, setWorkSpaceId, checked, setChecked }) => {
  const { Text } = Typography;

  useEffect(() => {
    if (checked === null) {
      return;
    }
    setWorkSpaceId(checked);
  }, [checked]);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Text
        className="required"
        style={{
          display: 'inline-block',
          marginBottom: '1rem',
          alignSelf: 'start',
        }}>
        {'You have multiple workspaces. Please select one:'}
      </Text>
      <div
        name="workspace"
        style={{
          width: '100%',
          maxHeight: '20rem',
          overflowY: 'auto',
          paddingRight: workSpaces?.length > 5 ? '12px' : '0',
        }}>
        <CardBoxGroup
          options={workSpaces}
          cardProps={{
            iconProps: {
              width: '30px',
              height: '30px',
            },
            bodyStyle: {
              padding: '0.5rem 1rem',
            },
          }}
          checked={checked}
          setChecked={setChecked}
        />
      </div>
    </div>
  );
};

export default WorkSpaceForm;
