import { Form, Button, Spin, Row, Col, Typography } from 'antd';
import Card from 'component/ui/card';
import SigninOptionFormElement from './SignOptionFormElement';
import {
  ENTER_VERIFICATION_CODE,
  LOGIN_PAGE,
  VERIFY,
  RESEND_CODE,
  BACK,
  NEXT,
} from 'constants';
import ModalForm from './ModalForm';
import VerifyCodeForm from './VerfiyCodeForm';
import WorkSpaceForm from './WorkSpaceForm';
import componyIcon from 'assets/images/companyIcon.svg';
import unlockSVG from 'assets/images/unlock.svg';
import { externalIdpsNames } from '../utils/index';
import { useState } from 'react';

const SignInOptionsForm = ({
  query,
  data,
  onSetRecovery,
  recovery,
  onSubmit,
  signInOptionsDataLoading,
  loading,
  showSelectWorkspace,
  workSpaceId,
  setWorkSpaceId,
  modalRef,
  refCaptcha,
  counterRef,
  modalToggle,
  onCloseModal,
  onSubmitCode,
  onResendForm,
  needCaptcha,
  workSpaces,
  getCodeLoading,
  onChangePage,
  modalLoading,
  redirectLoading,
}) => {
  const { externalIdps } = data ?? {};
  const [form] = Form.useForm();
  const [checked, setChecked] = useState(null);

  return (
    <Spin spinning={loading || getCodeLoading || redirectLoading}>
      <Card
        title={
          workSpaces?.length > 0
            ? LOGIN_PAGE.CHOOSE_TO_LOGIN
            : LOGIN_PAGE.LOGIN_OPTION
        }
        query={query}>
        <Form
          layout="vertical"
          form={form}
          style={{ display: 'flex', flexDirection: 'column' }}
          name="recoveryPassword"
          onFinish={() => {
            onSubmit({ type: checked });
          }}
          scrollToFirstError>
          {workSpaces?.length > 0 ? (
            <WorkSpaceForm
              workSpaces={workSpaces.map(({ _id, name, icon }) => ({
                id: _id,
                title: name,
                icon: icon || componyIcon,
              }))}
              setWorkSpaceId={setWorkSpaceId}
              checked={checked}
              setChecked={setChecked}
            />
          ) : (
            <SigninOptionFormElement
              state={data}
              loading={signInOptionsDataLoading}
              checked={checked}
              setChecked={setChecked}
              onSetRecovery={onSetRecovery}
              onSubmit={onSubmit}
            />
          )}
          {!(workSpaces?.length > 0) && externalIdps?.length > 0 && (
            <div style={{ marginTop: '24px' }}>
              <Typography.Text
                style={{
                  display: 'block',
                  fontWeight: 'bolder',
                  fontSize: '16px',
                  marginBottom: '24px',
                }}>
                Would you like to Sign-in via External IDPs?
              </Typography.Text>
              <div style={{ margin: '0px 10px' }}>
                <Row gutter={[16, 16]}>
                  {externalIdps.map((item, index) => (
                    <Col key={index} span={6}>
                      <Button
                        style={{
                          width: '100%',
                          height: 100,
                        }}
                        href={item.loginUrl}>
                        <Row gutter={[8, 8]}>
                          <Col key={index} span={24}>
                            <img
                              alt={item.name}
                              style={{ width: 24, height: 24 }}
                              src={item.image}
                            />
                          </Col>
                          <Col key={index} span={24}>
                            <Typography.Title level={5} ellipsis>
                              {externalIdpsNames(item.name)}
                            </Typography.Title>
                            <Typography.Text ellipsis>
                              {item.wsName}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Button>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: 'auto',
            }}>
            {showSelectWorkspace ? (
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                disabled={!workSpaceId}
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginTop: '24px',
                }}>
                {NEXT}
              </Button>
            ) : !(query?.sp && query?.email && query?.authType) ? (
              <Button onClick={onChangePage} style={{ marginTop: '24px' }}>
                {BACK}
              </Button>
            ) : null}
          </div>
        </Form>
        {modalToggle && (
          <ModalForm
            counterRef={counterRef}
            ref={modalRef}
            title={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img alt="" style={{ marginRight: '0.5rem' }} src={unlockSVG} />
                <span>{ENTER_VERIFICATION_CODE}</span>
              </div>
            }
            visible={modalToggle}
            submitForm={onSubmitCode}
            onCancel={onCloseModal}
            buttonTitle={VERIFY}
            hasTimer={true}
            finalMessage={RESEND_CODE}
            onTimerFinish={onResendForm}
            maskClosable={false}
            modalLoading={modalLoading}
            requiredMark={false}>
            <VerifyCodeForm
              needCaptcha={needCaptcha}
              validateCodeForm={modalRef}
              refCaptcha={refCaptcha}
              value={recovery}
              state={data}
            />
          </ModalForm>
        )}
      </Card>
    </Spin>
  );
};

export default SignInOptionsForm;
