import { useState, useEffect } from 'react';
import { Typography } from 'antd';
import logo from 'assets/images/logo-text.svg';
import * as config from 'config/config';
// import logo from 'assets/images/logo.png';
const CardTitle = ({ title, query }) => {
  const [imgUrl, setImgUrl] = useState(null);
  useEffect(() => {
    setImgUrl(
      query?.sp && !['idmelon', 'undefined'].includes(query?.sp.toLowerCase())
        ? `${
            config.SKM_URL
          }/sp/${query.sp.toLowerCase()}.png`
        : logo
    );
  }, [query]);
  return (
    <div
      style={{
        marginLeft: title ? '0' : '-8px',
        display: 'flex',
        justifyContent: title && imgUrl ? 'space-between' : 'flex-start',
        // flexDirection: 'row',
        alignItems: 'center',
        fontSize: '1.2rem',
        fontWeight: 500,
        color: '#808080',
      }}>
      <Typography.Title
        level={1}
        style={{
          fontSize: '1.4rem',
          fontWeight: '600',
          color: '#333',
          marginBottom: '0',
        }}>
        {title}
      </Typography.Title>
      {imgUrl && (
        <img
          alt="Logo"
          src={imgUrl}
          layout="fixed"
          width={150}
          height={50}
          style={{ objectPosition: 'left', objectFit: 'contain' }}
        />
      )}
    </div>
  );
};

export default CardTitle;
