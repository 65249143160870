export const ENVIRONMENT =
  process.env.REACT_APP_ENVIRONMENT || '${REACT_APP_ENVIRONMENT}';
export const SITEKEY_CAPTCHA =
  process.env.REACT_APP_HCAPTCHA_SITE_KEY || '${REACT_APP_HCAPTCHA_SITE_KEY}';
export const timeout =
  process.env.REACT_APP_PANEL_TIMEOUT || '${REACT_APP_PANEL_TIMEOUT}';
export const PANEL_URL =
  process.env.REACT_APP_PANEL_URL || '${REACT_APP_PANEL_URL}';
export const SSO_URL = process.env.REACT_APP_SSO_URL || '${REACT_APP_SSO_URL}';
export const SKM_URL = process.env.REACT_APP_SSO_REDIRECT_URL || '${REACT_APP_SSO_REDIRECT_URL}';
export const AUTO_ENROLLMENT_RP_ID =
  process.env.REACT_APP_AUTO_ENROLLMENT_RP_ID ||
  '${REACT_APP_AUTO_ENROLLMENT_RP_ID}';
export const LOGIN_CONSTANST = {
  loginURL: process.env.REACT_APP_SSO_URL
    ? `${process.env.REACT_APP_SSO_URL}/oidc/idp/authorize`
    : '${REACT_APP_SSO_URL}/oidc/idp/authorize',
  response_type:
    process.env.REACT_APP_SSO_LOGIN_RESPONSE_TYPE ||
    '${REACT_APP_SSO_LOGIN_RESPONSE_TYPE}',
  client_id:
    process.env.REACT_APP_SSO_LOGIN_CLIENT_ID ||
    '${REACT_APP_SSO_LOGIN_CLIENT_ID}',
  nonce:
    process.env.REACT_APP_SSO_LOGIN_NONCE || '${REACT_APP_SSO_LOGIN_NONCE}',
  state:
    process.env.REACT_APP_SSO_LOGIN_STATE || '${REACT_APP_SSO_LOGIN_STATE}',
  scope:
    process.env.REACT_APP_SSO_LOGIN_SCOPE || '${REACT_APP_SSO_LOGIN_SCOPE}',
  redirect_url: process.env.REACT_APP_SSO_REDIRECT_URL
    ? `${process.env.REACT_APP_SSO_REDIRECT_URL}/login/sso`
    : '${REACT_APP_SSO_REDIRECT_URL}/login/sso',
};
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || '${REACT_APP_SENTRY_DSN}';
