import { useQuery } from 'react-query';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { fetchSiginInOption } from '../api';

const useSigninOptionStartQuery = (query) => {
  const navigate = useNavigate();
  const { data, isLoading, error } = useQuery({
    queryKey: ['siginStart', query],
    queryFn: fetchSiginInOption,
    staleTime: 0,
    cacheTime: 0,
    retry: 2,
    refetchInterval: false,
    refetchOnWindowFocus: false,
  });

  if (error) {
    navigate({
      pathname: '/',
      search: `?${createSearchParams(query)}`,
    });
  }

  return { data, isLoading, error };
};

export default useSigninOptionStartQuery;
